<template>
    <v-layout column>
        <v-btn @click="openItem({})"
               :class="isMobile ? 'mobile-button' : 'add-button'"
               color="secondary">
            Добавить
        </v-btn>

        <label class="subtitle-1"
               style="margin-top: 25px;">{{adminCountLabel}}</label>

        <v-data-table :headers="headers"
                      v-if="items.length"
                      :items="items"
                      hide-default-footer
                      disable-sort
                      :mobile-breakpoint="200"
                      fixed-header
                      no-data-text="Список администраторов пуст."
                      loading-text="Загрузка"
                      :height="height - 160"
                      :items-per-page="perPage"
                      :loading="loading"
                      class="scroll-y"
                      style="margin-top: 20px; width: 100%; margin-left: -10px;">
            <template v-slot:item="items">
                <tr>
                    <td class="text-xs-left" style="width: 20%;">
                        <label class="text-truncate">{{items.item.login | truncated(20)}}</label>
                    </td>
                    <td class="text-xs-left" style="width: 20%;">
                        <label class="text-truncate">
                            {{items.item.name | truncated(30)}}</label>
                    </td>
                    <td class="text-xs-left" style="width: 20%;">
                        <label class="text-truncate subtitle-1 font-weight-bold pointer password-label"
                               @click="openPassword(items.item)">
                            Изменить пароль
                        </label>
                    </td>
                    <td class="text-xs-left" style="width: 40%;">
                        <v-switch v-model="items.item.isActivate"
                                  color="secondary"
                                  style="margin-top: 10px; margin-bottom: -10px;"
                                  @click.prevent="openDisableModal(items.item)"/>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="isVisibleRemoveModal"
                  max-width="590"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :persistent="removeLoading">
            <remove-modal :loading="removeLoading"
                          :error="removeError"
                          :title-text="modalTitleText"
                          :remove-error-text="removeErrorText"
                          @close="isVisibleRemoveModal = false"
                          @remove="curAction"/>
        </v-dialog>

        <v-dialog v-model="isVisibleEditModal"
                  max-width="650"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :persistent="editLoading">
            <admin-form :data="curItem"
                        @close="isVisibleEditModal = false"
                        @updateUser="updateUser($event)"
                        @addUser="addUser($event)"
                        @setLoading="editLoading = $event"
                        :loading="editLoading"
                        :is-visible="isVisibleEditModal"/>
        </v-dialog>

        <v-dialog v-model="isVisiblePasswordModal"
                  max-width="650"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :persistent="editLoading">
            <password-form :data="curItem"
                           @close="isVisiblePasswordModal = false"
                           @setLoading="editLoading = $event"
                           :loading="editLoading"
                           :is-visible="isVisiblePasswordModal"/>
        </v-dialog>

        <v-dialog v-model="isVisibleSuccessModal"
                  max-width="650"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :persistent="editLoading">
            <success-dialog @close="isVisibleSuccessModal = false"/>
        </v-dialog>
    </v-layout>
</template>

<script>
    import PaginationMixin from "../../mixins/PaginationMixin";
    import RemoveModal from "../RemoveModal";
    import AdminForm from "./AdminForm";
    import PasswordForm from "./PasswordForm";
    import SuccessDialog from "./SuccessDialog";

    export default {
        name: "SettingsList",
        components: {SuccessDialog, PasswordForm, AdminForm, RemoveModal},
        mixins: [PaginationMixin],
        data: () => ({
            options: {},
            headers: [
                {
                    text: 'Почта',
                    align: 'left',
                    sortable: false,
                    value: 'login',
                },
                {
                    text: 'Имя',
                    align: 'left',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Пароль',
                    align: 'left',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Статус',
                    align: 'left',
                    sortable: false,
                    value: 'isActivate',
                },
                {
                    text: '',
                    align: 'left',
                    sortable: false,
                    value: 'actions',
                }
            ],
            isVisibleRemoveModal: false,
            modalTitleText: '',
            removeErrorText: '',
            removeLoading: false,
            removeError: false,
            curItem: {},
            curAction: null,
            isVisibleEditModal: false,
            isVisiblePasswordModal: false,
            editLoading: false,
            isVisibleSuccessModal: false
        }),
        computed: {
            width() {
                return this.$store.state.width
            },
            height() {
                return this.$store.state.height
            },
            isMobile() {
                return this.$store.state.isMobile
            },
            countTitles() {
                return ['администратор', 'администраторов', 'администратора']
            },
            adminCountLabel() {
                const count = this.items.length;
                let index = 0;
                if (count) {
                    index = (count % 10 === 1) ? 0 : (count % 10 > 4) ? 1 : 2;
                    return `${count} ${this.countTitles[index]}`
                } else return 'Нет администраторов'
            }
        },
        methods: {
            getItems() {
                this.loading = true;

                const params = {
                    headers: {token: localStorage.getItem("token")},
                    params: {page: this.page - 1, limit: this.perPage}
                };

                this.$http.get("/stuff", params)
                    .then(response => {
                        this.items = response.data.data
                    }).catch(error => {
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            console.log('error');
                            break;
                    }
                }).finally(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                })
            },
            openDisableModal(item) {
                this.curAction = this.disableUser;
                this.curItem = item;
                this.modalTitleText = item.isActivate ? 'Вы уверены, что хотите отключить администратора?'
                    : 'Вы уверены, что хотите активировать администратора?';
                this.isVisibleRemoveModal = true
            },
            openItem(item) {
                this.curItem = item;
                this.isVisibleEditModal = true
            },
            disableUser() {
                this.removeLoading = true;
                const params = {
                    headers: {token: localStorage.getItem("token")}
                };

                const postData = {
                    idUser: this.curItem.idUser,
                    isActivate: !this.curItem.isActivate
                };

                this.$http.post("/stuff/control", postData, params)
                    .then(response => {
                        setTimeout(() => {
                            this.curItem.isActivate = !this.curItem.isActivate;
                            this.isVisibleRemoveModal = false;
                            this.removeLoading = false
                        }, 500)
                    }).catch(error => {
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 403:
                            console.log('forbidden');
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            console.log('error');
                            break;
                    }
                })
            },
            updateUser(userData) {
                this.items.forEach(v => {
                    if (v.idUser === userData.idUser) {
                        v.name = userData.name;
                        v.login = userData.login
                    }
                })
            },
            addUser(userData) {
                this.items.unshift(userData);
                this.isVisibleEditModal = false;
                this.isVisibleSuccessModal = true
            },
            openPassword(item) {
                this.curItem = item;
                this.isVisiblePasswordModal = true
            }
        }
    }
</script>

<style scoped>
    .add-button {
        margin-top: -40px;
        margin-left: 330px;
        width: 150px;
        border-radius: 8px;
        min-height: 45px;
    }

    .mobile-button {
        margin-top: 10px;
        width: 150px;
        border-radius: 8px;
        min-height: 45px;
    }

    .password-label:hover {
        color: grey
    }

    .pointer {
        cursor: pointer;
    }
</style>