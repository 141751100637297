<template>
    <v-form ref="form">
        <v-card :style="{padding: isMobile ? '10px' : '40px 20px 30px 20px'}">
            <v-card-title>
                <v-layout justify-space-between align-center>
                    <label class="display-1 font-weight-bold" style="word-break: normal;">
                        Новый администратор
                    </label>
                    <v-btn @click="$emit('close')"
                           :disabled="loading"
                           style="margin-top: -40px;"
                           :style="{'margin-right': isMobile ? '50px' : '-10px'}"
                           large
                           icon>
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-layout>
            </v-card-title>
            <v-card-text style="margin-top: 30px;">
                <v-layout :column="isMobile">
                    <label class="subtitle-1 field-label">
                        Имя
                    </label>
                    <v-text-field v-model="editedData.name"
                                  validate-on-blur
                                  color="secondary"
                                  style="max-width: 300px;"
                                  :rules="rules.nameRules"
                                  class="input-margin"/>
                </v-layout>

                <v-layout style="margin-top: 20px;" :column="isMobile">
                    <label class="subtitle-1 field-label">
                        Почта
                    </label>
                    <v-text-field v-model="editedData.login"
                                  :rules="rules.emailRules"
                                  color="secondary"
                                  style="max-width: 300px;"
                                  @input="emailIsTaken = false"
                                  validate-on-blur
                                  class="input-margin"/>
                </v-layout>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn :loading="loading"
                       @click="saveData"
                       color="secondary"
                       style="width: 150px; height: 45px; border-radius: 8px;">Создать
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import ValidationMixin from "../../mixins/ValidationMixin";

    let shajs = require('sha.js');

    export default {
        name: "AdminForm",
        mixins: [ValidationMixin],
        props: {
            data: {
                type: Object,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            },
            isVisible: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            isEditPassword: null,
            editedData: {}
        }),
        computed: {
            isMobile() {
                return this.$store.state.isMobile
            }
        },
        watch: {
            isVisible(to) {
                this.editedData = {...this.data};
                this.emailIsTaken = false;

                if (to && !this.data.idUser) this.isEditPassword = true;
                else if (to) this.isEditPassword = false;
                else this.isEditPassword = null;

                this.$refs.form.resetValidation()
            },
            isEditPassword() {
                this.editedData.password = null
            }
        },
        methods: {
            saveData() {
                if (this.$refs.form.validate()) {
                    this.$emit('setLoading', true)
                    const params = {headers: {token: localStorage.getItem('token')}};
                    let postData = {
                        idUser: this.data.idUser,
                        name: this.editedData.name
                    };

                    if (this.data.login !== this.editedData.login)
                        postData.login = this.editedData.login;

                    if (this.editedData.password)
                        postData["passwordHash"] = shajs('sha256').update(this.editedData.password).digest('hex');

                    this.$http.post(postData.idUser ? "/stuff/edit" : "/stuff/add", postData, params)
                        .then(response => {
                            this.$emit(postData.idUser ? 'updateUser' : 'addUser', response.data.data);
                        }).catch(error => {
                        console.log(error);
                        switch (error.response.status) {
                            case 406:
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            case 403:
                                this.emailIsTaken = true;
                                this.$nextTick().then(() => {
                                    this.$refs.form.validate()
                                });
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    }).finally(() => {
                        setTimeout(() => {
                            this.$emit('setLoading', false)
                        }, 500)
                    })

                }
            }
        },
        beforeMount() {
            this.editedData = {...this.data};
            this.isEditPassword = !this.data.idUser;
        }
    }
</script>

<style scoped>
    .input-margin {
        margin-top: -15px;
    }

    .field-label {
        width: 140px;
    }
</style>