<template>
    <v-layout class="content-layout" column>
        <label class="secondary--text display-1 font-weight-bold">Администраторы</label>
        <settings-list/>
    </v-layout>
</template>

<script>
    import SettingsList from "../components/settings/SettingsList";
    export default {
        name: "SettingsView",
        components: {SettingsList}
    }
</script>

<style scoped>

</style>