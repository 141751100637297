<template>
    <v-card light style="text-align: left; padding: 10px 5px 10px 5px;">
        <v-overlay absolute :value="error" opacity="0.9">
            <v-layout align-center justify-center column>
                <v-icon x-large color="secondary">mdi-close-circle</v-icon>
                <label class="text-center secondary--text">
                    {{removeErrorText}}</label>
            </v-layout>
        </v-overlay>

        <v-card-title class="headline font-weight-bold">Подтвердите действие</v-card-title>

        <v-card-text class="subtitle-1">
            {{titleText}}
        </v-card-text>

        <v-card-actions style="margin-top: 5px;">
            <v-spacer/>
            <v-btn
                    color="secondary"
                    text
                    class="subtitle-2"
                    :style="{width: isMobile ? '110px' : '140px' }"
                    style="border-radius: 8px; height: 40px;"
                    :loading="loading"
                    @click="$emit('close')"
            >
                Отменить
            </v-btn>

            <v-btn
                    color="secondary"
                    ref="acceptButton"
                    style="border-radius: 8px; margin-left: 50px; height: 40px;"
                    :style="{width: isMobile ? '110px' : '140px' }"
                    class="subtitle-2"
                    :loading="loading"
                    @click="$emit('remove')"
            >
                Подтвердить
            </v-btn>
            <v-spacer/>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "RemoveModal",
        props: {
            loading: {
                type: Boolean,
                required: true
            },
            error: {
                type: Boolean,
                required: true
            },
            removeErrorText: {
                type: String,
                required: true
            },
            titleText: {
                type: String,
                required: true
            }
        },

        computed: {
            isMobile() {
                return this.$store.state.isMobile
            }
        }
    }
</script>

<style scoped>

</style>