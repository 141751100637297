<template>
    <v-card>
        <v-card-title>
            <v-layout justify-space-between align-center>
                <label class="display-1 font-weight-bold">
                    Администратор добавлен
                </label>
            </v-layout>
        </v-card-title>
        <v-card-text style="margin-top: 10px;">
            <label class="subtitle-1">Письмо с приглашением отправлено на указанную почту.</label>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="$emit('close')"
                   color="secondary"
                   style="width: 150px; height: 45px; border-radius: 8px; margin: -10px 0 25px 15px;">Ок
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "SuccessDialog"
    }
</script>

<style scoped>

</style>