import { render, staticRenderFns } from "./SettingsList.vue?vue&type=template&id=398a6c92&scoped=true&"
import script from "./SettingsList.vue?vue&type=script&lang=js&"
export * from "./SettingsList.vue?vue&type=script&lang=js&"
import style0 from "./SettingsList.vue?vue&type=style&index=0&id=398a6c92&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "398a6c92",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VDataTable,VDialog,VLayout,VSwitch})
